import { AppApi } from '@/api/appApi';
import { ArticlesResponse } from '@/api/contracts/helpAndFAQs/articlesResponse';
import { ArticleByIdResponse } from '@/api/contracts/helpAndFAQs/articleByIdResponse';
import { FaqsResponse } from '@/api/contracts/helpAndFAQs/faqsResponse';
import { ArticlesRequest } from '../contracts/helpAndFAQs/articlesRequest';

export class HelpAndFAQsServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getArticles(params: ArticlesRequest): Promise<ArticlesResponse> {
    const response = await this.get(`/api/articles`, {
      params: {
        category: params.category,
        query: params.pagination.query,
        limit: params.pagination.limit,
        start: params.pagination.start,
        sort: params.pagination.sort
      }
    });
    return response.data as ArticlesResponse;
  }

  async getArticleById(id: guid): Promise<ArticleByIdResponse> {
    const response = await this.get(`/api/articles/${id}`);
    return response.data as ArticleByIdResponse;
  }

  async getFaqs(): Promise<FaqsResponse> {
    const response = await this.get('api/faqs');
    return response.data as FaqsResponse;
  }
}
