import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { States } from '@/models/posts/postModal';
import { MsoPostStoresDetails, PostListItem } from '@/models/posts/postListItem';
import { SocialChannels } from '@/models/posts/socialChannels';
import { DraftPost } from '@/models/posts/draftPost';
import { DraftResponse } from '@/models/posts/draftResponse';
import { SocialChannel } from '@/models/posts/socialChannels';
import { PostsServiceClient } from '@/api/clients/postsServiceClient';

const client = new PostsServiceClient();

const getDefaultState = (isMso: boolean) => {
  const ssoDefaults = {
    availableChannels: SocialChannels,
    id: null,
    visible: false,
    postTitle: '',
    postBody: '',
    immediate: true,
    date: null,
    time: null,
    currentState: States.NEW_POST,
    isDraft: false,
    editedPost: null,
    channels: [],
    isPostUpdated: false,
    isRecommended: false,
    usedTemplateId: ''
  };

  const msoDefaults = {
    ...ssoDefaults,
    currentStep: 1,
    msoPostId: '',
    msoPostStores: []
  };

  return isMso ? msoDefaults : ssoDefaults;
};

@Module({ dynamic: true, store, name: 'PostModalModule' })
export class PostModalModule extends VuexModule {
  public availableChannels: string[] = SocialChannels;
  public id: guid | null = null;
  public visible: boolean = false;
  public postTitle: string = '';
  public postBody: string = '';
  public immediate: boolean = true;
  public date: string | null = null;
  public time: string | null = null;
  public currentState: string = States.NEW_POST;
  public isDraft: boolean = false;
  public isRecommended: boolean = false;
  public editedPost: PostListItem | null = null;
  public usedTemplateId: guid = '';
  public channels: SocialChannel[] = [];
  public fullDate: number | Date | null = null;
  public isPostUpdated: boolean = false;
  public currentStep: number = 1;
  public msoPostId: guid = '';
  public msoPostStores: MsoPostStoresDetails[] = [];

  @Action
  public getMsoPostId(): Promise<guid> {
    return client.getMsoPostId();
  }

  @Action
  public saveDraftPost(draftPost: DraftPost): Promise<DraftResponse> {
    return client.saveDraftPost(draftPost);
  }

  @Action({ commit: 'updatePost' })
  public postUpdated(): void {
    this.updatePost();
  }

  @Action({ commit: 'setCurrentStep' })
  public stepTo(newStep: number): number {
    return newStep;
  }

  @Mutation
  public setMsoPostId(msoPostId: guid) {
    this.msoPostId = msoPostId
  }

  @Mutation
  public setMsoPostStoresDetails(details: MsoPostStoresDetails[]) {
    this.msoPostStores = details;
  }

  @Mutation
  private setCurrentStep(step: number) {
    this.currentStep = step;
  }

  @Mutation
  public updatePost(): void {
    this.isPostUpdated = true;
  }

  @Mutation
  public setAvailableChannels(channels: string[]): void {
    this.availableChannels = channels;
  }

  @Mutation
  public setIsDraft(isDraft: boolean): void {
    this.isDraft = isDraft;
  }

  @Mutation
  public setIsRecommended(isRecommended: boolean): void {
    this.isRecommended = isRecommended;
  }

  @Mutation
  public setEditedPost(editedPost: PostListItem): void {
    this.editedPost = Object.assign({}, editedPost);
  }

  @Mutation
  public setId(id: guid | null): void {
    this.id = id;
  }

  @Mutation
  public setVisible(visible: boolean): void {
    this.visible = visible;
  }

  @Mutation
  public setState(state: string): void {
    this.currentState = state;
  }

  @Mutation
  public setTitle(value: string): void {
    this.postTitle = value;
  }

  @Mutation
  public setChannels(value: SocialChannel[]): void {
    this.channels = value;
  }

  @Mutation
  public setBody(value: string): void {
    this.postBody = value;
  }

  @Mutation
  public setImmediate(value: boolean): void {
    this.immediate = value;
  }

  @Mutation
  public setFullDate(value: Date | number | null): void {
    this.fullDate = value;
  }

  @Mutation
  public setDate(value: string | null): void {
    this.date = value;
  }

  @Mutation
  public setTime(value: string | null): void {
    this.time = value;
  }

  @Mutation
  public setUsedTemplateId(value: guid | undefined): void {
    this.usedTemplateId = value || '';
  }

  @Mutation
  public resetState(isMso = false) {
    Object.assign(this, getDefaultState(isMso));
  }
}
