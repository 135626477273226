import { DataTableHeader } from 'vuetify';
import { SocialChannel } from './socialChannels';
import { Region } from '@/models/stores/regions';

export const States = {
  NEW_POST: 'NEW_POST',
  EXISTING_POST: 'EXISTING_POST'
};

interface DataTableSocialChannel {
  disabled: boolean;
  value: boolean;
  failedLoading?: boolean;
  unpublishing?: boolean;
}

export interface DataTableItem {
  id: guid;
  name: string;
  region: Region;
  facebook: DataTableSocialChannel;
  instagram: DataTableSocialChannel;
  // true if a store is creating a new post, updating an existing one otherwise
  isCreatingNewPost?: boolean;
}

export interface PublishedPostLinksItem {
  id: guid;
  name: string;
  region: Region;
  facebookUrl?: string;
  instagramUrl?: string;
  [key: string]: string | undefined;
}

export const PublishedPostsTableHeaders: DataTableHeader[] = [
  {
    text: 'Stores',
    value: 'name',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Facebook',
    value: 'facebookUrl'
  },
  {
    align: 'center',
    sortable: false,
    text: 'Instagram',
    value: 'instagramUrl'
  }
];

export type StoreChannelsMap = Map<
  guid,
  {
    selectedChannels: SocialChannel[];
    unpublishChannels: SocialChannel[];
    isCreatingNewPost?: boolean;
  }
>;
