import { MsoPostIdResponse } from '@/api/contracts/posts/postsResponse';
import {
  NewAssetPayload,
  NewMsoAssetPayload
} from '@/models/assets/newAssetPayload';
import { AppApi } from '@/api/appApi';
import { PagedAssetListResponse } from '@/api/contracts/assets';
import { AssetListRequest } from '@/models/assets/assetListRequest';
import { UserModule } from '@/store/userModule';
import { AssetModule } from '@/store/assetModule';
import { StoresModule } from '@/store/storesModule';
import { getModule } from 'vuex-module-decorators';
import { StickersCollection } from '@/api/contracts/assets/stickersCollection';
import { StickersRequest } from '@/models/assets/stickersRequest';
import { Region } from '@/models/stores/regions';
const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

export class AssetsServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async deleteAsset(payload: { assetId: guid; storeId: guid }) {
    return this.delete(
      `api/stores/${payload.storeId}/assets/${payload.assetId}`
    );
  }

  public async getAssets(
    params: AssetListRequest
  ): Promise<PagedAssetListResponse> {
    const assetModule = getModule(AssetModule);
    let storeId = userModule.currentStore.id;
    const forceSsoRequest: boolean =
      assetModule.selectedGroupFilter === 'national';
    if (forceSsoRequest) {
      // normally 'national' would hit the `api/mso/assets` endpoint which returns the same results for each store
      // this is a speed optimisation to return a single store result for 'national'
      storeId = storesModule.userStoresInBrandAndRegion[0].id;
    }
    const endpoint =
      userModule.isViewingSingleStore || forceSsoRequest
        ? `api/stores/${storeId}/assets`
        : `api/mso/assets`;
    const response = await this.get<PagedAssetListResponse>(endpoint, {
      params: {
        campaignId: params.campaignId,
        campaignCategoryId: params.campaignCategoryId,
        campaignSubcategoryId: params.campaignSubCategoryId,
        productTypeCategoryId: params.productTypeCategoryId,
        audienceCategoryId: params.audienceCategoryId,
        query: params.query,
        includeRecommended: params.includeRecommended,
        limit: params.pagination.limit,
        start: params.pagination.start || 0,
        sort: params.sort,
        state: params.state === Region.ALL ? 'national' : params.state,
        channel: params.channel
      }
    });
    return response.data as PagedAssetListResponse;
  }

  public async getStickers(
    params: StickersRequest
  ): Promise<StickersCollection> {
    const response = await this.get('api/stickers', {
      params: {
        query: params.query,
        limit: params.pagination.limit,
        start: params.pagination.start || 0,
        sort: params.pagination.sort
      }
    });

    return response.data as StickersCollection;
  }

  public async postAsset(payload: NewAssetPayload) {
    const { asset, storeId } = payload;
    return await this.post(`api/stores/${storeId}/assets`, asset);
  }

  public async postMsoAsset(payload: NewMsoAssetPayload) {
    const { asset, storeId, msoGuid } = payload;
    return await this.post(`api/mso/${storeId}/assets/${msoGuid}`, asset);
  }

  /**
   * Fetch for a guid to associate a batch of assets.
   * Called when trying to creat a new mso asset.
   * @returns guid
   */
  public async getSharedMsoAssetId(): Promise<guid> {
    const response = await this.get<MsoPostIdResponse>(
      'api/mso/posts/initital'
    );
    return response.data.data.msoGuid;
  }

  /**
   * Share an asset with all stores in the given region.
   * Sharig is only possible between stores within the same group.
   * @param payload.assetId Id of the asset being shared.
   * @param payload.storeId Id of the store that the given asset is saved to.
   * @param payload.region The region where the asset is shared to.
   */
  public async shareAsset(payload: {
    assetId: string;
    storeId: string;
    region: Region;
  }): Promise<void> {
    const { assetId, storeId, region } = payload;
    this.post(
      `api/mso/sharetogroup/${storeId}/asset/${assetId}?state=${region}`
    );
  }
}
