import { Region } from '@/models/stores/regions';
import { EventType } from '@/models/calendar/eventType';
import { DataTableHeader } from 'vuetify';
import { Brand, BrandName } from './models/stores/brands';

/**
 * Use this enum to get human readable labels for the associated event type.
 */
export enum EventTypesLabels {
  Campaigns = 'campaigns',
  RecommendedPosts = 'recommendedPosts',
  StorePosts = 'storePosts',
  DraftPosts = 'draftPosts',
  AutomatedPosts = 'automatedPosts'
}

export const EventTypes: Array<EventType> = [
  {
    name: EventTypesLabels.Campaigns,
    labelPlural: 'Campaigns',
    labelSingular: 'Campaign',
    colour: '#ddd'
  },
  {
    name: EventTypesLabels.RecommendedPosts,
    labelPlural: 'Recommended Posts',
    labelSingular: 'Recommended Post',
    colour: '#fdfc62'
  },
  {
    name: EventTypesLabels.StorePosts,
    labelPlural: 'Store Posts',
    labelSingular: 'Store Post',
    colour: '#b3c566'
  },
  {
    name: EventTypesLabels.DraftPosts,
    labelPlural: 'Draft Posts',
    labelSingular: 'Draft Post',
    colour: '#fdbe62'
  },
  {
    name: EventTypesLabels.AutomatedPosts,
    labelPlural: 'Automated Posts',
    labelSingular: 'Automated Post',
    colour: '#b1dde8'
  }
];

export enum LocalStorageKeys {
  TokenData = 'tokenData',
  User = 'user',
  AvailableBrands = 'availableBrands',
  CurrentStore = 'currentStore',
  CurrentRegion = 'currentRegion',
  CurrentBrand = 'currentBrand',
  LoginCompleted = 'loginCompleted',
  PrevBrandNameForLogin = 'prevBrandNameForLogin',
  PrevRegionForLogin = 'prevRegionForLogin'
}

export enum AltDrawerType {
  CampaignSidebar,
  UpcomingPostsSidebar
}

// Used to mark if the user is viewing all stores as opposed to specific one.
export const ALLSTORES = 'All stores';

// A dummy store that's assigned to currentStore when the user has
// not selected a specific store, i.e. they are in all stores view
export class DummyStoreForAllStores {
  public id = '';
  public state = '' as Region;
  public brand: Brand = {
    name: BrandName.IGA,
    key: null
  };

  public title = ALLSTORES;
  public isParticipatingInAutomatedProgram = true;

  constructor(state?: Region, brand?: Brand) {
    if (state) this.state = state;
    if (brand) this.brand = brand;
  }
}

export enum PostStatuses {
  Scheduled = 'Scheduled',
  InProgress = 'Posting in progress',
  Posted = 'Posted'
}

export const MsoPostTableHeaders: DataTableHeader[] = [
  {
    text: 'Stores',
    value: 'name',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Facebook',
    value: 'facebook'
  },
  {
    align: 'center',
    sortable: false,
    text: 'Instagram',
    value: 'instagram'
  }
];
