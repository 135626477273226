import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { DashboardResponse } from '@/api/contracts/dashboard/dashboardResponse';
import { DashboardServiceClient } from '@/api/clients/dashboardServiceClient';

const client = new DashboardServiceClient();

@Module({
  namespaced: true,
  name: 'DashboardModule',
  store,
  dynamic: true
})
export class DashboardModule extends VuexModule {
  public dashboardResponse: DashboardResponse = new DashboardResponse();

  get recentAssets() {
    return this.dashboardResponse?.data?.categories;
  }

  get quickLinks() {
    return this.dashboardResponse?.data?.cards;
  }

  @Action({ commit: 'setDashboardResponse' })
  async getDashboard(): Promise<DashboardResponse> {
    return await client.getDashboard();
  }

  @Mutation
  setDashboardResponse(dashboardResponse: DashboardResponse) {
    this.dashboardResponse = dashboardResponse;
  }
}
