import { AppApi } from '@/api/appApi';

// types
import { CampaignCategoriesResponse } from '@/api/contracts/campaigns';
import { PagedPresetListResponse } from '@/api/contracts/presets/pagedPresetListResponse';
import { PresetListRequest } from '@/models/presets/presetListRequest';

export class PresetsServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getPresets(
    params: PresetListRequest
  ): Promise<PagedPresetListResponse> {
    const endpoint = `api/presettemplates`;
    const response = await this.get<PagedPresetListResponse>(endpoint, {
      params: {
        presetTemplateCategoryId: params.presetTemplateCategoryId,
        isFeatured: params.isFeatured,
        limit: params.pagination?.limit,
        start: params.pagination?.start || 0,
        sort: params.pagination?.sort || 'None'
      }
    });
    return response.data as PagedPresetListResponse;
  }

  public async getPresetCategories(): Promise<CampaignCategoriesResponse> {
    const response = await this.get<CampaignCategoriesResponse>(
      `/api/categories/presettemplates`
    );
    return response.data;
  }

  public async getPresetSvg(url: string): Promise<any> {
    const response = await this.get<any>(url);
    return response.data;
  }
}
