export enum BrandName {
  IGA = 'IGA Retailer',
  LOCAL = 'Local Grocer',
  SUPA = 'supa'
}

export interface Brand {
  key: guid | null;
  name: BrandName;
}
