// CreateModule is responsible for the create page flow, presets and the stage
// CreateAssetModule is responsible for asset information, asset stores, asset channels etc
// CreatePostModule is responsible for post information, post stores, post channels etc

import Vue from 'vue';
import store from './store';
import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import { PresetsServiceClient } from '@/api/clients/presetsServiceClient';
import { forEach } from 'lodash';

// types
import { PagedPresetListResponse } from '@/api/contracts/presets/pagedPresetListResponse';
import { PresetListRequest } from '@/models/presets/presetListRequest';
import { PresetListItem, PresetLabel } from '@/models/presets/presetListItem';
import { AssetAspect } from '@/models/presets/assetAspect';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { AssetListItem } from '@/models/assets/assetListItem';
import { CreateSubmitTypes } from '@/models/createSubmitTypes';

const client = new PresetsServiceClient();
@Module({ namespaced: true, dynamic: true, store, name: 'CreateModule' })
export class CreateModule extends VuexModule {
  public currentStep: number = 1;
  public isStageImgLoaded: boolean = false; // initial false
  public isPresetLoading: boolean = false;
  public isAssetLibraryVisible: boolean = false;
  public stageImgUrl: string | null = null; // not sure this needs to be global
  public stageExport: string = '';
  public presetCategoryId: string = 'featured';
  public presetCategories: Array<CampaignCategory> = [];
  public presetsListResponse: PagedPresetListResponse = {
    pagination: { total: 0, limit: 0, start: 0, sort: '' },
    data: [],
  };
  public selectedPreset: PresetListItem = {
    id: 'none',
    title: 'None',
    presetLabels: [],
  };
  public assetAspect: AssetAspect = AssetAspect.Portrait;
  public selectedAsset: AssetListItem | null = null;
  public isSelectedAssetStageDirty: boolean = false;
  public submitSuccess: CreateSubmitTypes | null = null;

  @Action({ commit: 'setPresetsResponse' })
  async getPresets(
    params: PresetListRequest
  ): Promise<PagedPresetListResponse> {
    return await client.getPresets(params);
  }

  @Action({ commit: 'setPresetCategories' })
  async getPresetCategories() {
    const response = await client.getPresetCategories();
    return response.data;
  }

  @Mutation
  setPresetCategories(presetCategories: CampaignCategory[]) {
    this.presetCategories = presetCategories;
  }

  @Mutation
  setIsSelectedAssetStageDirty(flag: boolean) {
    this.isSelectedAssetStageDirty = flag;
  }

  @Mutation
  setPresetsResponse(pagedPresetList: PagedPresetListResponse): void {
    // replace http svg links with https
    pagedPresetList.data.forEach((preset: PresetListItem) => {
      // block may be removed if staging no longer serves thumbnails from /media umbraco assets
      const isCloudinary =
        !!preset?.presetThumbnailUrl?.includes('res.cloudinary');
      if (!isCloudinary) {
        if (preset.presetThumbnailUrl) {
          preset.presetThumbnailUrl = `${process.env.VUE_APP_API_ENDPOINT_PROXY}${preset.presetThumbnailUrl}`;
        }
      }

      if (preset.portraitTemplateFileUrl) {
        preset.portraitTemplateFileUrl = preset.portraitTemplateFileUrl.replace(
          'http://',
          'https://'
        );
      }
      if (preset.squareTemplateFileUrl) {
        preset.squareTemplateFileUrl = preset.squareTemplateFileUrl.replace(
          'http://',
          'https://'
        );
      }
    });

    this.presetsListResponse = Object.assign(
      {},
      this.presetsListResponse,
      pagedPresetList
    );
  }

  @Mutation
  public setCurrentStep(stepNumber: number): void {
    this.currentStep = stepNumber;
  }

  @Mutation
  public setIsStageImgLoaded(bool: boolean): void {
    this.isStageImgLoaded = bool;
  }

  @Mutation
  public setIsPresetLoading(bool: boolean): void {
    this.isPresetLoading = bool;
  }

  @Mutation
  public setStageImgUrl(url: string | null): void {
    this.stageImgUrl = url;
  }

  @Mutation
  public setStageExport(base64: string) {
    this.stageExport = base64;
  }

  @Mutation
  public setPresetCategoryId(categoryId: string): void {
    this.presetCategoryId = categoryId;
  }

  @Mutation
  public setSelectedPreset(preset: PresetListItem): void {
    forEach(preset.presetLabels, (label) => {
      label.currentText = label.defaultText;
    });
    this.selectedPreset = preset;
  }

  @Mutation
  public setPresetLabelCurrentText({
    value,
    labelId,
  }: {
    value: string;
    labelId: string;
  }): void {
    if (this.selectedPreset?.presetLabels?.length) {
      const presetLabels: PresetLabel[] = this.selectedPreset.presetLabels?.map(
        (label: PresetLabel) => {
          if (label.labelId === labelId) {
            label.currentText = value;
          }
          return label;
        }
      );
      Vue.set(this.selectedPreset, 'presetLabels', presetLabels);
    }
  }

  @Mutation
  public setAssetAspect(aspect: AssetAspect): void {
    this.assetAspect = aspect;
  }

  @Mutation
  public setSelectedAsset(asset: AssetListItem | null): void {
    this.selectedAsset = asset;
  }

  @Mutation
  public setSubmitSuccess(submitType: CreateSubmitTypes | null): void {
    this.submitSuccess = submitType;
  }

  @Mutation
  public setIsAssetLibraryVisible(flag: boolean): void {
    this.isAssetLibraryVisible = flag;
  }

  @Mutation
  public reset() {
    this.currentStep = 1;
    this.isStageImgLoaded = false;
    this.stageImgUrl = null;
    this.stageExport = '';
    this.selectedPreset = {
      id: 'none',
      title: 'None',
      presetLabels: [],
    };
    this.presetCategoryId = 'featured';
    this.assetAspect = AssetAspect.Portrait;
    this.selectedAsset = null;
    this.isSelectedAssetStageDirty = false;
    this.isAssetLibraryVisible = false;
  }
}
