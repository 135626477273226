import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// https://vuetifyjs.com/en/customization/icons/
const CUSTOM_ICONS = {
  complete: 'fal fa-check',
  // cancel: '...',
  close: 'fal fa-times',
  delete: 'fal fa-times', // delete (e.g. v-chip close)
  // clear: '...',
  // success: '...',
  info: 'fal fa-info-circle',
  // warning: '...',
  // error: '...',
  prev: 'fal fa-chevron-left',
  next: 'fal fa-chevron-right',
  checkboxOn: 'fal fa-check-square',
  checkboxOff: 'fal fa-square',
  checkboxIndeterminate: 'fal fa-minus-square',
  delimiter: 'fal fa-circle', // for carousel
  // sort: '...',
  expand: 'fal fa-chevron-down',
  menu: 'fal fa-bars',
  // subgroup: '...',
  dropdown: 'fal fa-chevron-down'
  // radioOn: 'fal fa-check-square',
  // radioOff: 'fal fa-square',
  // edit: '...',
  // ratingEmpty: '...',
  // ratingFull: '...',
  // ratingHalf: '...',
  // loading: '...',
  // first: '...',
  // last: '...',
  // unfold: '...',
  // file: '...',
};

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: '#e4291b',
        orange: '#fdbe62'
      },
      light: {
        primary: '#e4291b',
        orange: '#fdbe62'
      }
    }
  },
  icons: {
    iconfont: 'fa',
    values: CUSTOM_ICONS
  }
});
