export enum Region {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
  ALL = 'All regions'
}

type AustralianTimeZones = {
  [key in Region]?: string;
};

export const AustralianTimeZones: AustralianTimeZones = {
  [Region.ACT]: 'utc10',
  [Region.NSW]: 'utc10',
  [Region.QLD]: 'utc10',
  [Region.TAS]: 'utc10',
  [Region.VIC]: 'utc10',
  [Region.NT]: 'utc95',
  [Region.SA]: 'utc95',
  [Region.WA]: 'utc8'
};
