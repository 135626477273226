import { SocialChannel } from './../../models/posts/socialChannels';
import { Region } from './../../models/stores/regions';
import { AppApi } from '@/api/appApi';
import {
  StoreCountSummary,
  StoresResponse
} from '@/api/contracts/stores/storesResponse';
import { StoreDetails } from '@/models/settings/storeDetails';
import { UserModule } from '@/store/userModule';
import { getModule } from 'vuex-module-decorators';
import { StoresByStateResponse } from '@/api/contracts/stores/storesByStateResponse';
import { AdapterSSOLink } from '@/api/contracts/stores/adapterSSOLink';
import { UserStore } from '@/models/users/userStore';

const userModule = getModule(UserModule);

export class StoresServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getAdapterSSOLink(): Promise<AdapterSSOLink> {
    const response = await this.get(
      `/api/stores/${userModule.currentStore.id}/adapters/social-media/sso/link`
    );
    return response.data as AdapterSSOLink;
  }

  public async getStore(storeId: guid): Promise<StoresResponse> {
    const response = await this.get(`api/stores/${storeId}`);
    return response.data as StoresResponse;
  }

  public async getStoreChannels(storeId: guid): Promise<SocialChannel[]> {
    const response = await this.get(`api/stores/${storeId}/channels`);
    return response.data as SocialChannel[];
  }

  public async getStoresByState(state: string): Promise<StoresByStateResponse> {
    const response = await this.get<StoresByStateResponse>(
      `api/stores/${state}`
    );
    return response.data as StoresByStateResponse;
  }

  public async updateStore(store: StoreDetails): Promise<StoresResponse> {
    const response = await this.put(
      `api/stores/${userModule.currentStore?.id}`,
      store
    );

    return response.data as StoresResponse;
  }

  /**
   * This will call Ayrshare to get an updated list of authorised social channels.
   * @param storeId
   * @return Promise<string[]>
   */
  public async updateSocialChannels(storeId: guid): Promise<SocialChannel[]> {
    const response = await this.put(`api/stores/${storeId}/channels`);
    return response.data as SocialChannel[];
  }

  /* ----------------------- */
  /* MSO Related calls below */
  /* ----------------------- */

  /**
   * Calling an mso endpoint to fetch all user stores in a given state,
   * ignoring the `region` parameter when fetching for all regions.
   * @param region state code, e.g NSW
   */
  public async getStoresInRegion(region: Region): Promise<UserStore[]> {
    const state = region === Region.ALL ? 'national' : region;
    const response = await this.get(`api/mso/stores/${state}`);
    return response.data as UserStore[];
  }

  public async getStoreCount(): Promise<StoreCountSummary> {
    const response = await this.get(`api/mso/storescount`);
    return response.data as StoreCountSummary;
  }
}
