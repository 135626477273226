import { RouteConfig } from 'vue-router';
import { RouteMetaDefinition } from '@/router/meta';

// Meta data is an object that defines a route, you can check against meta data in RouteGuards.
const authedMeta: RouteMetaDefinition = {
  auth: true,
  layout: () => import('../../layouts/authenticatedLayout.vue')
};

const routes: RouteConfig[] = [
  {
    path: '/help-and-faqs',
    meta: authedMeta,
    components: {
      default: () => import('./module.vue')
    },
    children: [
      {
        name: 'helpAndFaqs.root',
        path: '',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      },
      {
        name: 'helpAndFaqs.faqs',
        path: '/help-and-faqs/faqs',
        meta: authedMeta,
        component: () => import('./views/faqs.vue')
      },
      {
        name: 'helpAndFaqs.category',
        path: '/help-and-faqs/:articleId',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      },
      {
        name: 'helpAndFaqs.articleDetail',
        path: '/help-and-faqs/article/:id',
        meta: authedMeta,
        component: () => import('./views/articleDetail.vue')
      }
    ]
  }
];

export default routes;
