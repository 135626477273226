import { DailyPostsGroup } from '@/models/posts/dailyPostsGroup';
import { PostAndCampaignsData } from '@/api/contracts/calendar/calendarEventsResponse';
import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CalendarEvent } from '@/models/calendar/calendarEvent';
import { CalendarServiceClient } from '@/api/clients/calendarServiceClient';
import { PostListItem } from '@/models/posts/postListItem';
import EventFactory from '@/services/eventFactory';
import { DateFormatter } from '@/models/dateFormats';
import add from 'date-fns/add';
import { isDate } from 'date-fns';
import { CampaignListItem } from '@/api/contracts/campaigns';

const client = new CalendarServiceClient();

interface UpcomingPostsData {
  posts: PostListItem[];
  fromDate: Date;
  toDate: Date;
}

@Module({
  namespaced: true,
  name: 'CalendarModule',
  store,
  dynamic: true
})
export class CalendarModule extends VuexModule {
  // Start and End date of upcoming posts sidebar
  private fromDate: Date | null = null;
  private toDate: Date | null = null;
  private rawPosts: PostListItem[] = [];
  private rawCampaigns: CampaignListItem[] = [];
  private _upcomingPosts: PostListItem[] = [];

  public get upcomingPosts(): DailyPostsGroup[] {
    const groupDateFormatter: DateFormatter = {
      formatter: 'eeee dd/MM/yyyy',
      isRelative: true
    };

    return EventFactory.getPostsToDisplay(
      this._upcomingPosts,
      groupDateFormatter,
      this.toDate!,
      this.fromDate!
    );
  }

  public get calendarEvents(): CalendarEvent[] {
    return EventFactory.getCalendarEvents({
      campaigns: this.rawCampaigns,
      posts: this.rawPosts
    });
  }

  @Action({ commit: 'setPostsAndCampaigns' })
  public async getPostsAndCampaigns(payload?: {
    fromDate?: Date | string | number;
    toDate?: Date | string | number;
  }): Promise<PostAndCampaignsData> {
    let fromDate = payload?.fromDate ?? new Date();
    let toDate = payload?.toDate;
    fromDate = (isDate(fromDate) ? fromDate : new Date(fromDate)) as Date;
    fromDate.setHours(0, 0, 0, 0);
    if (toDate) {
      toDate = (isDate(toDate) ? toDate : new Date(toDate)) as Date;
    }
    return await client.getPostsAndCampaigns(
      fromDate.toISOString(),
      toDate ? (toDate as Date).toISOString() : undefined
    );
  }

  @Action
  public async getUpcomingPosts(totalDays: number = 4): Promise<void> {
    const fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);
    const toDate = add(fromDate, { days: totalDays - 1 });
    const data = await client.getPostsAndCampaigns(
      fromDate.toISOString(),
      toDate.toISOString()
    );
    const posts = data.posts;
    this.context.commit('setUpcomingPosts', {
      posts,
      fromDate,
      toDate
    });
  }

  @Mutation
  public setUpcomingPosts({ posts, fromDate, toDate }: UpcomingPostsData) {
    this._upcomingPosts = posts;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  @Mutation
  public setPostsAndCampaigns(data: PostAndCampaignsData): void {
    this.rawPosts = data.posts;
    this.rawCampaigns = data.campaigns;
  }
}
