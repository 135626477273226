// TODO:
// keep track of refresh token received time so we can notify user if session has expired before redirecting. Check on timeout and on navigation?
// possibility of refreshToken timeout duration being incorrect if tab is inactive - use dayJS to check within expiration period?
// 401 unauthorised on token refresh should notify user session has expired before redirecting.
// 'Your session has expired. Please login.'

// App imports.
import { UserModule } from '@/store/userModule';
import { getModule } from 'vuex-module-decorators';
import router from '@/router';

// Generic imports.
import { LocalStorageKeys } from '@/constants';
import axios from 'axios';

const userModule = getModule(UserModule);

export default class RefreshTokenService {
  public static refreshTokenTimeout = 0;

  public static async refreshToken(): Promise<void> {
    // Check if we have token data stored in localstorage. If not, redirect to the login page.
    const tokenString = localStorage.getItem(LocalStorageKeys.TokenData);
    if (tokenString) {
      const tokenData = JSON.parse(tokenString);

      // If we have a refresh token, get a new token and reset the timer,
      // otherwise redirect to login page.
      if (tokenData.refresh_token) {
        /* eslint-disable @typescript-eslint/camelcase */
        await userModule
          .refreshJwtToken({
            refresh_token: tokenData.refresh_token
          })
          .then(async response => {
            if (!userModule.tokenExpiresIn)
              throw new Error('No token expiry time available.');

            localStorage.setItem(
              LocalStorageKeys.TokenData,
              JSON.stringify(response)
            );

            this.startRefreshTokenTimeout();

            // redirect to home from login
            if (
              userModule.currentUser &&
              router.currentRoute.name === 'public.login'
            ) {
              await router.push({ name: 'home.root' });
            }
          })
          .catch(async err => {
            // Token refresh was invalid so redirect to login page
            console.error(err);
            await router.push({ name: 'public.login' });
          });

        // continue
      } else {
        await router.push({ name: 'public.login' });
      }
    } else {
      await router.push({ name: 'public.login' });
    }
  }

  public static startRefreshTokenTimeout(): void {
    if (userModule.tokenExpiresIn) {
      const tokenExpiresInMs = parseInt(userModule.tokenExpiresIn) * 1000;

      // Set a refresh time of the token expiry less 20%.
      const refreshTimeout = tokenExpiresInMs - tokenExpiresInMs * 0.2;

      // Set timer to refresh token before it expires
      this.refreshTokenTimeout = setTimeout(async () => {
        await this.refreshToken();
      }, refreshTimeout);
    }
  }

  public static stopRefreshTokenTimeout(): void {
    // on logout
    clearTimeout(this.refreshTokenTimeout);
  }
}
