// Pre app instantiations.
import './services/loading'; // Imported early, in order to register axios interceptor before anything else
import './services/errors';
import './classComponentHooks';

// App imports.
import Vue from 'vue';
import App from './App.vue';
import router, { routeGuardManager } from '@/router';
import VueGtm from '@gtm-support/vue2-gtm';
import store from './store';
import vuetify from './plugins/vuetify';
import RefreshTokenService from './services/refreshTokenService';

import { UserModule } from '@/store/userModule';
import { StoresModule } from '@/store/storesModule';
import { getModule } from 'vuex-module-decorators';

const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

// Generic imports.
import './auth';
import './registerServiceWorker';
import './globalComponents';
import './styles/main.scss';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import { LocalStorageKeys } from '@/constants';
import { Region } from './models/stores/regions';
import { Brand } from './models/stores/brands';

// https://github.com/vuetifyjs/vuetify/issues/12224
import { Ripple } from 'vuetify/lib/directives';
import Vuetify from 'vuetify';
Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

Vue.config.productionTip = false;

if (process.env.VUE_APP_GTM) {
  // https://www.npmjs.com/package/@gtm-support/vue2-gtm
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
    debug: process.env.NODE_ENV !== 'production', // display console logs on staging
    vueRouter: router,
  });
}

// This must happen after inclusion of ./auth in order to make
// sure that the authentication route guards are registered before
// the application route guards.
routeGuardManager.registerRouteGuards();

new Vue({
  router,
  store,
  vuetify,
  async created() {
    const userString = localStorage.getItem(LocalStorageKeys.User);
    const loginCompleted = localStorage.getItem(
      LocalStorageKeys.LoginCompleted
    );
    if (userString && loginCompleted) {
      const userData = JSON.parse(userString);
      userModule.setCurrentUser(userData);
      await RefreshTokenService.refreshToken();
    }

    const storeString = localStorage.getItem(LocalStorageKeys.CurrentStore);
    if (storeString) {
      const storeData = JSON.parse(storeString);
      userModule.setCurrentStore(storeData);
      storesModule.currentStoreSet(true);
    }

    const currentRegion = localStorage.getItem(
      LocalStorageKeys.CurrentRegion
    ) as Region;
    if (currentRegion) {
      storesModule.setCurrentRegion(currentRegion);
    }

    const currentBrandStr = localStorage.getItem(LocalStorageKeys.CurrentBrand);
    if (currentBrandStr) {
      const currentBrand = JSON.parse(currentBrandStr) as Brand;
      storesModule.setCurrentBrand(currentBrand);
    }

    const availableBrandsStr = localStorage.getItem(
      LocalStorageKeys.AvailableBrands
    );
    if (availableBrandsStr) {
      const availableBrands = JSON.parse(availableBrandsStr) as Brand[];
      storesModule.setAvailableBrands(availableBrands);
    }
  },
  render: (h) => h(App),
}).$mount('#app');
