import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { UiModule } from '@/store';
import { getModule } from 'vuex-module-decorators';

class LoadingInterceptor {
  private pendingRequests: number = 0;
  private uiModule = getModule(UiModule);

  private isComplete(): boolean {
    return this.pendingRequests === 0;
  }

  private clearLoaderState(): void {
    this.uiModule.setLoading(false);
  }

  public addPending(): void {
    this.pendingRequests++;
    this.uiModule.setLoading(true);
  }

  public completePending(): void {
    this.pendingRequests--;
    if (this.isComplete()) {
      this.clearLoaderState();
    }
  }
}

const loading = new LoadingInterceptor();

axios.interceptors.request.use((request: AxiosRequestConfig) => {
  loading.addPending();
  return request;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    loading.completePending();
    return response;
  },
  (error: AxiosError) => {
    loading.completePending();
    return Promise.reject(error);
  }
);
