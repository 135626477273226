import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { HelpAndFAQsServiceClient } from '@/api/clients/helpAndFAQsServiceClient';
import { Article } from '@/api/contracts/helpAndFAQs/article';
import { ArticlesResponse } from '@/api/contracts/helpAndFAQs/articlesResponse';
import { PaginationResponse } from '@/api/contracts/pagination/paginationResponse';
import { FaqsResponse } from '@/api/contracts/helpAndFAQs/faqsResponse';
import { Faq } from '@/api/contracts/helpAndFAQs/faq';
import { ArticlesRequest } from '@/api/contracts/helpAndFAQs/articlesRequest';
import { CategoriesServiceClient } from '@/api/clients/categoriesServiceClient';
import { ArticleCategory } from '@/api/contracts/helpAndFAQs/articleCategory';

const helpAndFAQsServiceClient = new HelpAndFAQsServiceClient();
const categoriesServiceClient = new CategoriesServiceClient();

@Module({
  dynamic: true,
  store,
  name: 'HelpAndFAQsModule'
})
export class HelpAndFAQsModule extends VuexModule {
  public articles: Article[] = [];
  public articlesPagination: PaginationResponse = {
    total: 0,
    limit: 0,
    start: 0,
    sort: ''
  };
  public currentHelpArticle: Article | null = null;
  public currentArticleFilterId: guid = '';
  public faqs: Faq[] = [];
  private faqsPagination: PaginationResponse = {
    total: 0,
    limit: 0,
    start: 0,
    sort: ''
  };
  public fullArticleCategories: ArticleCategory[] = [];

  @Action({ commit: 'setArticles' })
  public async getArticles(params: ArticlesRequest): Promise<ArticlesResponse> {
    return await helpAndFAQsServiceClient.getArticles(params);
  }

  @Action({ commit: 'setCurrentHelpArticle' })
  public async getArticleById(id: guid): Promise<Article> {
    const response = await helpAndFAQsServiceClient.getArticleById(id);
    return response.data;
  }

  @Action({ commit: 'setFaqs' })
  public async getFaqs(): Promise<FaqsResponse> {
    return await helpAndFAQsServiceClient.getFaqs();
  }

  @Action
  public async filterArticles(params: ArticlesRequest): Promise<void> {
    this.context.commit('setCurrentArticleFilterId', params.category);
    await this.getArticles(params);
  }

  @Action({ commit: 'setFullArticleCategories' })
  public async getArticleCategories(): Promise<ArticleCategory[]> {
    const response = await categoriesServiceClient.getArticleCategories();
    return response.data.map(item => {
      const { id, title, description } = item;
      return { id, title, description };
    });
  }

  @Mutation
  public setFullArticleCategories(categories: ArticleCategory[]) {
    this.fullArticleCategories = categories;
  }

  @Mutation
  public setArticles(articles: ArticlesResponse) {
    this.articles = articles.data;
    this.articlesPagination = articles.pagination;
  }

  @Mutation
  public setCurrentArticleFilterId(filter: guid): void {
    this.currentArticleFilterId = filter;
  }

  @Mutation
  public setCurrentHelpArticle(article: Article) {
    this.currentHelpArticle = article;
  }

  @Mutation
  public setFaqs(faqs: FaqsResponse) {
    this.faqs = faqs.data;
    this.faqsPagination = faqs.pagination;
  }
}
