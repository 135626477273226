import { AppApi } from '@/api/appApi';
import { UpcomingPostsResponse } from '@/api/contracts/assets/upcomingPostsResponse';
import { DraftPost } from '@/models/posts/draftPost';
import { PostListItem } from '@/models/posts/postListItem';
import { PublishPostRequest } from '@/api/contracts/posts/publishPostRequest';
import { MsoPostIdResponse } from '@/api/contracts/posts/postsResponse';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/userModule';

const userModule = getModule(UserModule);

export class PostsServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async deletePost(
    storeId: guid,
    postId?: guid,
    msoPostId?: guid
  ): Promise<boolean> {
    const endpoint = msoPostId
      ? `api/mso/${storeId}/posts/${msoPostId}`
      : `/api/stores/${storeId}/posts/${postId}`;
    try {
      await this.delete(endpoint);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async unpublishPost(
    storeId: guid,
    postId?: guid,
    msoPostId?: guid,
    channels?: string[] | null | undefined
  ): Promise<boolean> {
    const channelsString = channels
      ?.map((c, i) => (i > 0 ? '&' : '') + 'channels=' + c)
      .join('');

    const endpoint = msoPostId
      ? `api/mso/${storeId}/posts/${msoPostId}/unpublish?${channelsString}`
      : `/api/stores/${storeId}/posts/${postId}/unpublish?${channelsString}`;
    try {
      await this.post(endpoint);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async getUpcomingPosts(): Promise<PostListItem[] | undefined> {
    try {
      const response = await this.get<UpcomingPostsResponse>(
        `/api/stores/${userModule.currentStore?.id}/posts`
      );
      return response.data.data as PostListItem[];
    } catch (err) {
      console.error(err);
    }
  }

  // eslint-disable-next-line
  public async updatePost(
    storeId: guid,
    post: PostListItem,
    msoPostId?: guid
  ): Promise<any> {
    const endpoint = msoPostId
      ? `api/mso/${storeId}/posts/${msoPostId}`
      : `api/stores/${storeId}/posts`;
    try {
      return await this.put(endpoint, post);
    } catch (err) {
      console.error(err);
    }
  }

  // eslint-disable-next-line
  public async saveDraftPost(post: DraftPost): Promise<any> {
    const { storeId, msoPostId } = post;
    const endpoint = msoPostId
      ? `api/mso/${storeId}/posts/${msoPostId}`
      : `api/stores/${storeId}/posts`;
    try {
      return await this.post(endpoint, post);
    } catch (err) {
      console.error(err);
    }
  }

  public async publishPost(
    params: PublishPostRequest,
    storeId: guid,
    // eslint-disable-next-line
  ): Promise<any> {
    const postId = params.postId;
    const apiUrl = `api/stores/${storeId}/posts/${postId}/publish`;
    const channels = params.channels?.map(c => '&channels=' + c).join('');

    return await this.post(
      `${apiUrl}?isDraft=${params.isDraft}${channels}${params.publishTime &&
        '&publishTime=' + encodeURIComponent(params.publishTime)}`
    );
  }

  public async getMsoPostId(): Promise<guid> {
    const response = await this.get<MsoPostIdResponse>(
      'api/mso/posts/initital'
    );
    return response.data.data.msoGuid;
  }
}
