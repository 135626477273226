import { getModule } from 'vuex-module-decorators';
import { AppApi } from '@/api/appApi';
import { DashboardResponse } from '@/api/contracts/dashboard/dashboardResponse';
import { UserModule } from '@/store/userModule';
import { StoresModule } from '@/store/storesModule';

const storesModule = getModule(StoresModule);
const userModule = getModule(UserModule);

export class DashboardServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getDashboard(): Promise<DashboardResponse> {
    const endpoint = userModule.isViewingSingleStore
      ? `api/appdashboard/${userModule.currentStore.id}`
      : `api/mso/appdashboard/${storesModule.currentRegion}`;
    const response = await this.get<DashboardResponse>(endpoint);
    return response.data as DashboardResponse;
  }
}
