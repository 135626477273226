import { PostAndCampaignsData } from '@/api/contracts/calendar/calendarEventsResponse';
import { CalendarEvent } from '@/models/calendar/calendarEvent';
import { DailyPostsGroup } from '@/models/posts/dailyPostsGroup';
import { EventTypesLabels } from '@/constants';
import { PostListItem } from '@/models/posts/postListItem';
import _ from 'lodash';
import {
  add,
  addMinutes,
  eachDayOfInterval,
  format,
  formatISO,
  isAfter,
  isBefore,
  isDate,
  isToday,
  isTomorrow,
  sub
} from 'date-fns';
import { DateFormatter } from '@/models/dateFormats';
import { UserModule } from '@/store/userModule';
import { StoresModule } from '@/store/storesModule';
import { getModule } from 'vuex-module-decorators';

const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

/**
 * Register global services that handle transforming:
 *   - posts
 *   - campaigns
 * into desired formats that are used to render corresponding views.
 */
export default class EventFactory {
  public static setPostType(post: PostListItem): void {
    // isDraft will take priority over other flags.
    if (post.isDraft) {
      post.postType = EventTypesLabels.DraftPosts;
    } else if (post.usedTemplateId) {
      post.postType = EventTypesLabels.StorePosts;
    } else if (post.isAutomated) {
      post.postType = EventTypesLabels.AutomatedPosts;
    } else if (post.isRecommended) {
      post.postType = EventTypesLabels.RecommendedPosts;
    } else {
      post.postType = EventTypesLabels.StorePosts;
    }
  }

  /**
   * Set the availability of a post for the current store.
   * A post is not available for this store only if:
   * - the post is a recommended post and
   * - the store is not on any of the post's available channel.
   * This method depends on the type of the given post, so {@link setPostType} is called on it inside
   * @param post A post list item.
   */
  public static setPostAvailability(post: PostListItem): void {
    post.postType || this.setPostType(post);
    if (post.postType === EventTypesLabels.RecommendedPosts) {
      if (!userModule.isViewingSingleStore) {
        post.isAvailableToCurrentStore = true;
      } else {
        post.isAvailableToCurrentStore = post.availableChannels.some(channel =>
          storesModule.storeChannels.includes(channel)
        );
      }
    }
  }

  /**
   * Returns the actual date of a given post in ISO-8601 format. The actual date of a post is the date it's
   * posted or the suggested date if it has not been posted yet.
   * @param post a raw post item.
   * @param dateOnly a flag indicating if the time should be omitted.
   * @returns string
   */
  public static getActualDate(
    post: PostListItem,
    dateOnly: boolean = false
  ): string {
    const date = new Date(
      post.publishedDateTime || post.suggestedPublishDateTime
    );
    if (dateOnly) date.setHours(0, 0, 0, 0);
    return formatISO(date);
  }

  /**
   * Group an array of posts by dates and give each group a fomatted date string.
   * @param posts A flat collection of post items.
   * @returns An array of `DailyPostsGroup` items which has a date and an array of posts.
   */
  private static groupPostsByDate(
    posts: PostListItem[],
    groupDateFormatter: DateFormatter,
    startDate: Date,
    endDate: Date
  ): DailyPostsGroup[] {
    const result = _(posts)
      .groupBy(post => this.getActualDate(post, true))
      .value();

    this.fillInEmptyDates<typeof result>(result, startDate, endDate);

    return Object.entries(result).map(([key, value]) => ({
      date: new Date(key),
      displayedDate: this.getDisplayedDate(key, groupDateFormatter),
      posts: value
    }));
  }

  /**
   * Formats the given date string according to the given formatter.
   * @external "date-fns"
   * @param date The date
   * @param formatter The string formatter used by date-fns.
   * @param isRelative Whether or not format the date relative to today.
   * @returns The formatted string.
   */
  public static getDisplayedDate(
    date: string | number | Date,
    { formatter, isRelative = false }: DateFormatter
  ) {
    const temp = isDate(date) ? (date as Date) : new Date(date);
    const displayedDate = format(temp, formatter);

    if (isRelative && isToday(temp))
      return `Today ${displayedDate.split(' ')[1]}`;
    if (isRelative && isTomorrow(temp))
      return `Tomorrow ${displayedDate.split(' ')[1]}`;
    return displayedDate;
  }

  private static fillInEmptyDates<T extends object>(
    postsGroups: T,
    startDate: Date,
    endDate: Date
  ): void {
    const days = eachDayOfInterval({ start: startDate, end: endDate });
    days.forEach(day => {
      const dateString = formatISO(day);
      _.has(postsGroups, dateString) || _.set(postsGroups, dateString, []);
    });
  }

  /**
   * Filter out the posts that was published or are scheduled between the given start and end dates.
   * @param posts An array of raw post items.
   * @param startDate The start date, inclusive
   * @param endDate The end date, inclusive
   * @returns An array of filtered raw post items.
   */
  private static postsBetweenDates(
    posts: PostListItem[],
    startDate: Date,
    endDate: Date
  ): PostListItem[] {
    const startExclusive = sub(startDate, { days: 1 });
    const endExclusive = add(endDate, { days: 1 });
    return posts.filter(post => {
      const actualDate = new Date(this.getActualDate(post, true));
      return (
        isAfter(actualDate, startExclusive) &&
        isBefore(actualDate, endExclusive)
      );
    });
  }

  /**
   * If a `recommended post` has been used as a template to create at least 1 draft/scheduled post,
   * we hide that original `Recommended Post`.
   * */
  private static removeUsedRecommendedPosts(
    posts: PostListItem[]
  ): PostListItem[] {
    const result: PostListItem[] = [];

    posts.forEach(post => {
      if (post.postType === EventTypesLabels.RecommendedPosts) {
        const recommendedPostId = post.id;
        if (!posts.some(x => x.usedTemplateId === recommendedPostId)) {
          result.push(post);
        }
      } else if (!result.some(result => result.id === post.id)) {
        result.push(post);
      }
    });
    return result;
  }

  private static removeAutomatedPosts(posts: PostListItem[]): PostListItem[] {
    return posts.filter(
      post => post.postType !== EventTypesLabels.AutomatedPosts
    );
  }

  /**
   * Massage and group a flat array of raw post items by the date they belong to.
   * @param posts An array of raw post items.
   * @param groupDateFormatter The formatter used to format the date of a single day.
   * @param endDate The end day, inclusive
   * @param startDate The start day, inclusive, default to today
   * @returns
   */
  public static getPostsToDisplay(
    posts: PostListItem[],
    groupDateFormatter: DateFormatter,
    endDate: Date,
    startDate: Date = new Date(new Date().setHours(0, 0, 0, 0))
  ): DailyPostsGroup[] {
    const postsBetweenDates = this.postsBetweenDates(posts, startDate, endDate);
    postsBetweenDates.forEach(post => {
      this.setPostAvailability(post);
    });
    let temp = this.removeUsedRecommendedPosts(postsBetweenDates);
    if (!userModule.isCurrentStoreInAutomatedProgram) {
      temp = this.removeAutomatedPosts(temp);
    }
    const result = this.groupPostsByDate(
      temp,
      groupDateFormatter,
      startDate,
      endDate
    );
    result.sort((a, b) => (isAfter(a.date, b.date) ? 1 : -1));
    return result;
  }

  public static getPostChannels(post: PostListItem): string[] {
    let result;
    if (post.isMsoPost && !userModule.isViewingSingleStore) {
      result = post.msoPostChannelStatus!;
    } else {
      result =
        post.channels.length > 0 ? post.channels : post.availableChannels;
    }
    return [...result].sort();
  }

  public static getCalendarEvents({
    campaigns,
    posts
  }: PostAndCampaignsData): CalendarEvent[] {
    const campaignEvnets = campaigns.map(campaign => ({
      ...campaign,
      eventType: EventTypesLabels.Campaigns,
      name: campaign.title,
      start: new Date(campaign.startDateTime),
      end: new Date(campaign.endDateTime)
    }));

    const postEvents = posts.map(post => {
      this.setPostAvailability(post);

      const eventType: string = post.postType;
      let name: string = '';
      let start: Date | string = '';
      let end: Date | string = '';
      const timed: boolean = true;

      switch (post.postType) {
        case EventTypesLabels.AutomatedPosts: {
          name = `${post.title} automated post`;
          start = new Date(post.suggestedPublishDateTime);
          end = addMinutes(start, 10) || '';
          break;
        }
        case EventTypesLabels.RecommendedPosts: {
          name = `${post.title} recommended post`;
          start = new Date(post.suggestedPublishDateTime);
          end = addMinutes(start, 10) || '';
          break;
        }
        case EventTypesLabels.StorePosts: {
          name = `${post.title} post`;
          start = new Date(post.publishedDateTime) || '';
          end = addMinutes(start, 10) || '';
          break;
        }
        case EventTypesLabels.DraftPosts: {
          name = `${post.title} post`;
          start = new Date(post.suggestedPublishDateTime) || '';
          end = addMinutes(start, 10) || '';
          break;
        }
      }

      return {
        ...post,
        eventType,
        name,
        start,
        end,
        timed
      };
    });

    return [...postEvents, ...campaignEvnets] as CalendarEvent[];
  }
}

export const getActualDate = EventFactory.getActualDate;
export const getDisplayedDate = EventFactory.getDisplayedDate;
export const getPostChannels = EventFactory.getPostChannels;
