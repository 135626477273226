import { AppApi } from '@/api/appApi';
import { LoginCredentials, TokenResult } from '@/api/contracts/identity';
import { TokenValidationParams } from '@/api/contracts/identity/tokenValidationParams';
import { FetchUserResponse } from '@/api/contracts/storeUser/fetchUserResponse';
import { ChangePasswordParams } from '@/models/users/changePasswordParams';
import { RegisterRequestParams } from '@/models/users/registerRequestParams';
import { EmailConfirmParams } from '@/api/clients/emailConfirmParams';
import { UpdateProfileParams } from '@/api/clients/updateProfileParams';
import { ForgotPasswordParams } from '@/models/users/forgotPasswordParams';
import { RefreshTokenParams } from '@/api/contracts/identity/refreshTokenParams';

export class UserServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async emailConfirm(params: EmailConfirmParams) {
    return await this.post(`api/users/account/email/confirm`, params);
  }

  public async login(params: LoginCredentials): Promise<TokenResult> {
    params['client_id'] = process.env.VUE_APP_API_CLIENT_ID;
    params['client_secret'] = process.env.VUE_APP_API_CLIENT_SECRET;
    params['grant_type'] = 'password';

    const response = await this.post<TokenResult>('auth/token', params);
    return response.data;
  }

  public async ssoLogout(): Promise<void> {
    await this.get('sso/logout');
  }

  public async refreshToken(params: RefreshTokenParams): Promise<TokenResult> {
    params['client_id'] = process.env.VUE_APP_API_CLIENT_ID;
    params['client_secret'] = process.env.VUE_APP_API_CLIENT_SECRET;
    params['grant_type'] = 'refresh_token';

    const response = await this.post<TokenResult>('auth/token', params);
    return response.data;
  }

  public async fetchUser(
    username: string,
    password: string
  ): Promise<FetchUserResponse> {
    const response = await this.post<FetchUserResponse>(
      'api/users/account/validate',
      {
        username,
        password
      }
    );
    return response.data;
  }

  /**
   * This is used when a user registers for the first time.
   * @param {RegisterRequestParams} params
   */
  public async registerUser(params: RegisterRequestParams) {
    const response = await this.post(`api/users/account`, params);
    return response.data;
  }

  public async tokenValidation(params: TokenValidationParams) {
    return await this.post(
      `api/users/account/recovery/tokenvalidation`,
      params
    );
  }

  public async updateProfile(params: UpdateProfileParams) {
    const response = await this.put(`api/users/account`, params);
    return response.data;
  }

  public async changePassword(changePasswordParams: ChangePasswordParams) {
    const response = await this.post(
      'api/users/account/recovery/password/change',
      {
        username: changePasswordParams.username,
        newPassword: changePasswordParams.newPassword,
        token: changePasswordParams.token
      }
    );

    return response.data;
  }

  public async resetPassword(params: ForgotPasswordParams) {
    const response = await this.post(
      `/api/users/account/password/reset/request`,
      params
    );
    return response.data;
  }

  public async validateToken(params: TokenValidationParams) {
    const response = await this.post(
      'api/users/account/recovery/tokenvalidation',
      params
    );
    return response.data;
  }
}
