import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignDetailParams } from '@/models/campaigns/campaignDetailParams';

export class CampaignDetail {
  id: guid = '';
  title: string = '';
  startDateTime: datestring = '';
  endDateTime: datestring = '';
  categories: Array<CampaignCategory> = [];
  description: string = '';
  blobUrl: string = '';

  constructor(params?: CampaignDetailParams) {
    Object.assign(this, params);
  }
}
