import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import { UiModule } from '@/store';
import ApplicationUser from '@/models/auth/applicationUser';

export abstract class BaseAppComponent extends Vue {
  protected uiModule = getModule(UiModule);

  public get isLoading(): boolean {
    return this.uiModule.isLoading;
  }

  public get user(): ApplicationUser | null {
    return this.user;
  }
}
