import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CampaignServiceClient } from '@/api';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { CampaignDetail } from '@/models/campaigns/campaignDetail';
import { CampaignsByCategoryRequest } from '@/models/campaigns/campaignsByCategoryRequest';
import { addMonths } from 'date-fns';
import { PostListItem } from '@/models/posts/postListItem';
import { CampaignsRequest } from '@/models/campaigns/campaignsRequest';
import { CampaignSubCategoriesResponse } from '@/api/contracts/campaigns';
import { CampaignAssetsResponse } from '@/api/contracts/campaigns/campaignAssetsResponse';
import { CampaignAssetListParams } from '@/models/campaigns/campaignAssetListParams';
import { CampaignPostsParams } from '@/api/clients/campaignPostsParams';
import EventFactory from '@/services/eventFactory';

const client = new CampaignServiceClient();

@Module({
  namespaced: true,
  name: 'CampaignModule',
  store,
  dynamic: true
})
export class CampaignModule extends VuexModule {
  campaigns: Array<CampaignDetail> = [];
  campaignsByCampaignCategory: CampaignSubCategoriesResponse = {
    data: {
      id: '',
      title: '',
      subcategories: [{ id: '', title: '', campaigns: [] }]
    }
  };
  currentCampaignCategory: CampaignCategory = {
    id: '',
    title: '',
    description: ''
  };
  campaignDetail: CampaignDetail = new CampaignDetail();

  public campaignCategories: Array<CampaignCategory> = [];
  public campaignPosts: Array<PostListItem> = [];
  public upcomingCampaigns: Array<CampaignDetail> = [];
  public campaignAssetsResponse: CampaignAssetsResponse = {
    pagination: { total: 0, limit: 0, sort: '', start: 0 },
    data: []
  };

  @Action({ commit: 'setCampaignAssets' })
  async getCampaignAssets(
    params: CampaignAssetListParams
  ): Promise<CampaignAssetsResponse> {
    return await client.getCampaignAssets(params);
  }

  @Action({ commit: 'setCampaignsByCampaignCategory' })
  async getCampaignsByCampaignCategory(
    params: CampaignsByCategoryRequest
  ): Promise<CampaignSubCategoriesResponse> {
    return await client.getCampaignsByCategoryId(params);
  }

  @Action({ commit: 'setCampaignCategories' })
  async getCampaignCategories() {
    const response = await client.getCampaignCategories();
    return response.data;
  }

  /**
   * Get current and upcoming campaigns for the next 3 months.
   */
  @Action({ commit: 'setUpcomingCampaigns' })
  async getUpcomingCampaigns() {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = addMonths(startDate, 3);

    const response = await client.getCampaigns(
      new CampaignsRequest(
        startDate.toISOString(),
        endDate.toISOString(),
        '',
        null
      )
    );
    return response.data;
  }

  @Action({ commit: 'setCampaignDetail', rawError: true })
  async getCampaignDetail(id: guid) {
    const response = await client.getCampaignDetail(id);
    return response.data;
  }

  @Action({ commit: 'setCampaignPosts' })
  async getCampaignPosts(params: CampaignPostsParams) {
    const response = await client.getCampaignPosts(params);
    response.data.forEach(post => {
      EventFactory.setPostAvailability(post);
    })
    return response.data;
  }

  @Mutation
  setCampaigns(campaigns: Array<CampaignDetail>) {
    this.campaigns = campaigns;
  }

  @Mutation
  setCampaignsByCampaignCategory(
    campaignResponse: CampaignSubCategoriesResponse
  ) {
    this.campaignsByCampaignCategory = campaignResponse;
  }

  @Mutation
  setCampaignAssets(campaignAssetsResponse: CampaignAssetsResponse) {
    this.campaignAssetsResponse = campaignAssetsResponse;
  }

  @Mutation
  setCampaignCategories(campaignCategories: CampaignCategory[]) {
    this.campaignCategories = campaignCategories;
  }

  @Mutation
  setCampaignDetail(campaignDetail: CampaignDetail) {
    this.campaignDetail = campaignDetail;
  }

  @Mutation
  setCampaignPosts(campaignPosts: Array<PostListItem>) {
    this.campaignPosts = campaignPosts;
  }

  @Mutation
  setCurrentCampaignCategory(categoryId: guid) {
    // grab the campaign id from url
    // find the campaign data and set
    // categories can't be assigned, subactegories are the highest level
    const campaign = this.campaignCategories?.find(
      category => category.id === categoryId
    );
    // find in sub category
    const campaignInSub = this.campaignCategories?.find(
      category => {
        const subFound = category.subCategories?.find(sub => sub.id === categoryId)
        if (subFound) { // the id is inside the subCategories
          return true
        }
        return false // go next
      }
    );
    if (campaign) {
      this.currentCampaignCategory = campaign;
    } else if (campaignInSub) {
      this.currentCampaignCategory = campaignInSub;
    } else {
      console.warn('No campaign found');
    }
  }

  @Mutation
  setUpcomingCampaigns(upcomingCampaigns: Array<CampaignDetail>): void {
    const sortedUpcomingCampaigns: Array<CampaignDetail> = upcomingCampaigns.slice(0);
    sortedUpcomingCampaigns.sort((a, b) => {
      return (
        new Date(a.startDateTime).getTime() -
        new Date(b.startDateTime).getTime()
      );
    });
    this.upcomingCampaigns = Object.assign(
      {},
      this.upcomingCampaigns,
      sortedUpcomingCampaigns
    );
  }
}
