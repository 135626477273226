import { StoresModule } from '@/store/storesModule';
import { UserModule } from '@/store/userModule';
import { DateFormatter } from '@/models/dateFormats/index';
import { HomeResponse, HomeData } from '@/api/contracts/home/homeResponse';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import store from '@/store/store';
import { HomeServiceClient } from '@/api/clients/homeServiceClient';
import EventFactory from '@/services/eventFactory';
import add from 'date-fns/add';
import { ALLSTORES } from '@/constants';

const client = new HomeServiceClient();
const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

@Module({
  namespaced: true,
  name: 'HomeModule',
  store,
  dynamic: true
})
export class HomeModule extends VuexModule {
  private rawHomeData: HomeData | null = null;

  get highlightCards() {
    return this.rawHomeData?.highlightCards || [];
  }

  get campaigns() {
    const result = this.rawHomeData?.campaigns || [];
    return [...result].sort((a, b) => {
      return (
        new Date(a.startDateTime).getTime() -
        new Date(b.startDateTime).getTime()
      );
    });
  }

  get dailyPosts() {
    if (this.rawHomeData?.posts) {
      const groupDateFormatter: DateFormatter = {
        formatter: 'eeee dd/MM/yyyy',
        isRelative: true
      };
      return EventFactory.getPostsToDisplay(
        this.rawHomeData.posts,
        groupDateFormatter,
        add(new Date(this.rawHomeData.endDay), { weeks: 1 }),
        new Date(this.rawHomeData.startDay)
      );
    }
    return [];
  }

  get supplierCampaigns() {
    return this.rawHomeData?.supplierCards || [];
  }

  get startDay() {
    return this.rawHomeData?.startDay || '';
  }

  get endDay() {
    return this.rawHomeData?.endDay || '';
  }

  @Action({ commit: 'setHomeData' })
  async getHomeData(): Promise<HomeResponse> {
    const storeId =
      userModule.currentStore.title === ALLSTORES
        ? undefined
        : userModule.currentStore.id;
    const region = storesModule.currentRegion;
    const response = await client.getHomeEvents(region, storeId);
    return response;
  }

  @Mutation
  setHomeData(rawHomeData: HomeResponse): void {
    this.rawHomeData = rawHomeData.data;
  }
}
