import store from './store';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Sticker } from '@/api/contracts/assets/sticker';

@Module({ dynamic: true, store, name: 'StickerModalModule' })
export class StickerModalModule extends VuexModule {
  public selectedSticker: Sticker | null = null;
  public visible: boolean = false;

  @Mutation
  public setFindStickerModalVisible(visible: boolean): void {
    this.visible = visible;
  }

  @Mutation
  public setSelectedSticker(sticker: Sticker | null): void {
    this.selectedSticker = sticker;
  }
}
